<script lang="ts">
import { TransitionPresets, useIntervalFn, useTransition } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { useAppBreakpoints } from '@peng_kai/theme/vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function formatMillionsEn(num: number) {
  if (num >= 100000000)
    return `${(num / 100000000).toFixed(1)}B`
  else if (num >= 1000000)
    return `${(num / 1000000).toFixed(1)}M`
  else
    return num.toString()
}

function formatMillionsZh(num: number) {
  if (num >= 100000000)
    return `${(num / 100000000).toFixed(1)}亿`
  else if (num >= 1000000)
    return `${(num / 10000).toFixed(0)}万`
  else
    return num.toString()
}

function useStatisticalNum(num: Ref<number>) {
  const gtMobilel = toRef(useAppBreakpoints(), 'mobilel')
  const { locale } = useNuxtApp().$i18n
  const localNum = ref(0)
  const numT = useTransition(localNum, {
    duration: 1500,
    transition: TransitionPresets.easeOutExpo,
  })

  watch(num, (num) => {
    if (num)
      localNum.value = num
  }, { immediate: true })

  const finalNum = computed(() => {
    const _locale = locale.value
    const _gtMobilel = gtMobilel.value
    const _num = num.value
    const _numT = numT.value

    if (_locale === 'en')
      return _gtMobilel ? formatAmount(_numT, 0) : formatMillionsEn(_num)
    else
      return _gtMobilel ? formatAmount(_numT, 0) : formatMillionsZh(_num)
  })

  return finalNum
}

function useMotion() {
  gsap.registerPlugin(ScrollTrigger)

  onMounted(() => {
    const ctx = getCurrentInstance()
    const triggerEl = ctx?.vnode.el as HTMLElement
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
      },
    })

    tl.fromTo(
      triggerEl.querySelector('.motion--left_num'),
      { opacity: 0, translateX: -100 },
      { opacity: 1, translateX: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelector('.motion--center_num'),
      { opacity: 0, translateY: -50 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelector('.motion--right_num'),
      { opacity: 0, translateX: 100 },
      { opacity: 1, translateX: 0, duration: 0.5 },
      0,
    )
  })
}
</script>

<script lang="ts" setup>
const usableEnergy = useQuery({
  queryKey: [apis.usableEnergy.id],
  refetchInterval: 5 * 1000,
  queryFn: () => apis.usableEnergy(undefined),
})
const totalEnergy = useStatisticalNum(toRef(() => usableEnergy.data.value?.total_energy ?? 0))
const energyValue = useStatisticalNum(toRef(() => usableEnergy.data.value?.energy_value ?? 0))
const saveTrx = useStatisticalNum(toRef(() => usableEnergy.data.value?.save_trx ?? 0))

await usableEnergy.suspense()

useAppBreakpoints().laptop && useMotion()
</script>

<template>
  <div class="bg-#161D36 relative overflow-hidden statistics">
    <div class="flex mx-auto py-$padding-y justify-between page-content-width">
      <div class="item motion--left_num">
        <div class="num">{{ totalEnergy }}</div>
        <div class="title">{{ $t('ywl0XvgjqUtsr5aa1AWgN') }}</div>
      </div>
      <div class="item motion--center_num">
        <div class="num">{{ energyValue }}</div>
        <div class="title">{{ $t('U0JmPhD0fDyEkL_t_FB_n') }}</div>
      </div>
      <div class="item motion--right_num">
        <div class="num">{{ saveTrx }}</div>
        <div class="title">{{ $t('sX1uk8bGiJ7sZxARAcxoa') }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.statistics {
  --padding-y: 50px;

  @media bp-lt-tabletl{
    --padding-y: 30px;
  }

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    display: block;
    width: 1700px;
    height: var(--padding-y);
    content: '';
    background: radial-gradient(closest-side, rgb(38 109 241 / 50%), rgba(255 255 255 / 0%));

    @media bp-lt-tabletl{
      width: 100%;
    }
  }

  &::before {
    top: 0;
    transform: translate(-50%, -50%);
  }

  &::after {
    bottom: 0;
    transform: rotate(180deg) translate(50%, -50%);
  }
}

.item {
  flex: 1;
  text-align: center;

  @media bp-lt-tabletl {
    &:nth-of-type(1) {
      text-align: left;
    }

    &:nth-of-type(3) {
      text-align: right;
    }
  }

  .num {
    margin-bottom: 14px;
    font-size: 40px;
    font-weight: 800;
    font-variant-numeric: tabular-nums;
    color: #E2E8F2;

    @media bp-lt-tabletl {
      margin-bottom: 10px;
      font-size: 24px;
    }
  }

  .title {
    font-size: 18px;
    color: #8098BF;

    @media bp-lt-tabletl{
      font-size: 12px;
    }
  }
}
</style>
