<script lang="ts">
import { useAppBreakpoints } from '@peng_kai/theme/vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function useMotion() {
  gsap.registerPlugin(ScrollTrigger)

  onMounted(() => {
    const ctx = getCurrentInstance()
    const triggerEl = ctx?.vnode.el as HTMLElement
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
        start: '+=50% bottom',
      },
    })

    tl.fromTo(
      triggerEl.querySelector('.motion--main-title'),
      { opacity: 0, translateY: -100 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelectorAll('.motion--content .header'),
      { opacity: 0, translateX: 100 },
      { opacity: 1, translateX: 0, stagger: 0.1, duration: 0.5 },
    )
  })
}
</script>

<script lang="ts" setup>
useAppBreakpoints().laptop && useMotion()
</script>

<template>
  <section id="partner" class="bg-[var(--bg-two)] py-105px overflow-hidden min-h-[400px] flex items-center at-tabletl:py-100px lt-tabletl:py-50px lt-tabletl:min-h-0 introduce-wrapper">
    <div class="page-content-width mx-auto main">
      <h2 class="text-center text-50px text-white lh-normal  font-700 mb-44px at-tabletl:text-44px lt-tabletl:text-center lt-tabletl:text-30px lt-tabletl:font-bold lt-tabletl:mb-30px section-title motion--main-title">{{ $t('necasD5ndOJvyEQtNhjLf') }}</h2>

      <div class="grid gap-67px grid-cols-4 lt-laptop:gap-20px lt-laptop:grid-cols-2 section-content motion--content">
        <div class="item">
          <div class="header">
            <img class="icon" src="@/assets/imgs/page-index/parnter-tron.svg">
            <span class="title">TRON</span>
          </div>
        </div>
        <div class="item">
          <div class="header">
            <img class="icon" src="@/assets/imgs/page-index/parnter-tronlink.svg">
            <span class="title">Tronlink</span>
          </div>
        </div>
        <div class="item">
          <div class="header">
            <img class="icon" src="@/assets/imgs/page-index/parnter-ttpay.svg">
            <span class="title">TTPay.io</span>
          </div>
        </div>
        <div class="item">
          <div class="header">
            <img class="icon" src="@/assets/imgs/imToken-logo.svg">
            <span class="title">imToken</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 80px;
  background: #202c52;
  border-radius: 10px;

  @media bp-lt-laptop {
    width: auto;
    height: 80px;
  }

  @media bp-lt-mobile {
    // width: 170px;
    height: 44px;
  }

  &:hover{
    transform: scale(1.05);
  }

  .header {
    display: flex;
    align-items: center;
    color: #8098bf;

    .icon {
      display: block;
      width: 35px;
      height: 35px;
      margin-right: 16px;
      font-size: 35px;

      @media bp-lt-mobilel {
        width: 20px;
        height: 20px;
        margin-right: 7px;
        font-size: 20px;
      }
    }

    .title {
      font-size: 24px;

      @media bp-lt-mobilel {
        font-size: 15px;
      }
    }
  }
}
</style>
