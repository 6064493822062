<script lang="ts">
import { useAppBreakpoints } from '@peng_kai/theme/vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function useMotion() {
  gsap.registerPlugin(ScrollTrigger)

  onMounted(() => {
    const ctx = getCurrentInstance()
    const triggerEl = ctx?.vnode.el as HTMLElement
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
        start: '+=50% bottom',
      },
    })

    tl.fromTo(
      triggerEl.querySelector('.motion--main-title'),
      { opacity: 0, translateY: -100 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelector('.motion--intro'),
      { opacity: 0, translateY: 100 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelectorAll('.motion--content .header'),
      { opacity: 0, translateX: 100 },
      { opacity: 1, translateX: 0, stagger: 0.1, duration: 0.5 },
    )
    tl.fromTo(
      triggerEl.querySelectorAll('.motion--content .text'),
      { opacity: 0, translateY: 100 },
      { opacity: 1, translateY: 0, stagger: 0.1, duration: 0.5 },
      '<',
    )
  })
}
</script>

<script lang="ts" setup>
useAppBreakpoints().laptop && useMotion()
</script>

<template>
  <section id="introduce" class="bg-#161D36 py-170px overflow-hidden min-h-[1000px] flex items-center at-tabletl:py-100px lt-tabletl:py-60px lt-tabletl:min-h-0 introduce-wrapper">
    <div class="page-content-width mx-auto main">
      <h2 class="text-center text-50px text-white lh-normal  font-700 at-tabletl:text-44px lt-tabletl:text-center lt-tabletl:text-30px lt-tabletl:font-bold section-title motion--main-title">{{ $t('B5Etrd9g7WMF7ZY8YveyF') }}</h2>
      <p class="text-center text-20px text-#8098BF lh-snug mt-24px mb-60px pb-50px border-b-1px border-b-solid border-b-#2C314B at-tabletl:text-18px lt-tabletl:text-14px lt-tabletl:mt-20px lt-tabletl:mb-30px lt-tabletl:pb-20px lt-tabletl:px-15px intro-text motion--intro">{{ $t('9PyFQJDCeapt7wEWe8eKo') }}</p>

      <div class="grid gap-160px grid-cols-3 at-tabletl:gap-80px lt-tabletl:gap-40px lt-tabletl:grid-cols-1 lt-tabletl:px-15px section-content motion--content">
        <div class="item">
          <div class="header">
            <img class="icon" src="../../../assets/imgs/page-index/intro-economy.svg">
            <span class="title">{{ $t('-b3o_tma0U2HPrWFbb5Xp') }}</span>
          </div>
          <div class="text">{{ $t('EoyxwZC6kmTBfj2xmpY6u') }}</div>
        </div>
        <div class="item">
          <div class="header">
            <img class="icon" src="../../../assets/imgs/page-index/intro-efficient.svg">
            <span class="title">{{ $t('CQ1VU0CUv4qtR-PYiX3vY') }}</span>
          </div>
          <div class="text">{{ $t('kGBngVRWMwksduG-HteZ9') }}</div>
        </div>
        <div class="item">
          <div class="header">
            <img class="icon" src="../../../assets/imgs/page-index/intro-safety.svg">
            <span class="title">{{ $t('O_k60EoBd0iV0g3GE5MK-') }}</span>
          </div>
          <div class="text">{{ $t('j4Er7oDJNLyvGpy7W2auc') }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.item {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
    color: rgb(226 232 242);

    @media bp-lt-tabletl {
      margin-bottom: 20px;
    }

    .icon {
      display: block;
      width: 70px;
      height: 70px;

      @media bp-at-tabletl {
        width: 60px;
        height: 60px;
      }

      @media bp-lt-tabletl {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      margin-left: 20px;
      font-size: 30px;

      @media bp-lt-tabletl {
        margin-left: 16px;
        font-size: 20px;
      }
    }
  }

  .text {
    color: #8098BF;
    font-size: 20px;
    line-height: 1.5em;

    @media bp-at-tabletl {
      font-size: 18px;
    }

    @media bp-lt-tabletl {
      font-size: 14px;
    }
  }
}
</style>
