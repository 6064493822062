<script lang="ts" setup>
import animationData from '~/assets/imgs/json/FAQ.json'
import { useAnimation } from '@/hooks/useAnimation'

const faqRef = ref<Element | null>(null)

useAnimation(faqRef, animationData)

const list = computed(() => [
  {
    id: 0,
    name: $t('cIzh0GseyQ-eBJeUIB_Jt'),
    text: $t('W9xT0xOEz2Fqskw1Rn7Xv'),
    url: '/blog/help/what-is-wave-field-energy-is-energy-free-3',
  },
  {
    id: 1,
    name: $t('yPPSdb3necWCi2jXy_ECS'),
    text: $t('yIx3M1U0v8TFjoVJ8onwl'),
    url: '/blog/help/what-is-energy-used-for-4',
  },
  {
    id: 2,
    name: $t('sUKidGrWZnSFvQcc-oubC'),
    text: $t('KqygIFeBrzgqPjxfARewX'),
    url: '/blog/help/hot-to-get-energy-16',
  },
  {
    id: 3,
    name: $t('nbZ4Ekjr6u1KfyeyKpU0Q'),
    text: $t('u5nY6m2Fza99JTaNrG9gV'),
    url: '/blog/help/how-to-rent-energy-bandwidth-5',
  },
  {
    id: 4,
    name: $t('dhrAMzBPKaFhML2pw5inr'),
    text: $t('IuKNWe9De_k3mBKmwKv2h'),
    url: '/blog/help/after-the-payment-is-successful-when-will-the-energy-arrive-6',
  },
  {
    id: 5,
    name: $t('PxzuC9RPKQd8WVphjzWbw'),
    text: $t('Sfpe36OhBW_FlyLPdxMJU'),
    url: '/blog/help/how-long-does-it-take-for-energy-to-be-fully-restored-after-use-7',
  },
  {
    id: 6,
    name: $t('bMO5PvbA0szb_-nqJFnwh'),
    text: $t('LzrF6CsFgGZCBYh0_FwRy'),
    url: '/blog/help/can-an-energy-rental-order-be-withdrawn-8',
  },
])

const sel = ref(0)
// 定位
const height = ref()
const top = ref()
function openBox(e: number) {
  sel.value = e
  setTimeout(() => {
    getInfo()
  })
}

onMounted(() => {
  getInfo()
})
function getInfo() {
  height.value = document.querySelector('.faq-active')?.clientHeight
  top.value = document.querySelector('.faq-active')?.offsetTop
}
</script>

<template>
  <section v-cloak id="faq" class="py-170px at-tabletl:py-100px lt-tabletl:py-50px faqs-mean-wrapper">
    <div class="page-content-width mx-auto main flex justify-between items-center lt-tabletl:justify-center">
      <div ref="faqRef" class="block w-650px h-auto at-tabletl:w-500px lt-tabletl:hidden" />

      <div class="relative max-w-600px pr-40px w-full at-tabletl:max-w-500px lt-tabletl:h-auto collapse-wrap">
        <div class="htm-bar" :style="`top: ${top}px; height: ${height}px;`" />
        <div class="faq-content">
          <div class="faq-content-wrap">
            <div
              v-for="item in list" :id="`sol-${item.id}`" :key="item.id"
              class="faq"
              :class="sel === item.id ? 'faq-active' : 'false'"
            >
              <div class="faq-text" @click="openBox(item.id)" data-test-id="b0w6">
                <img src="~/assets/imgs/dot.svg" class="mt-12px mr-13px lt-mobilel:hidden">
                <span class="text-30px max-w-590px lt-mobilel:text-22px lt-mobilel:max-w-320px lt-mobilel:px-15px">{{ item.name }}</span>
                <i class="icon i-ep:arrow-down text-22px mt-10px ml-auto" :class="sel === item.id ? 'flipy' : ''" />
              </div>
              <div class="faq-desc" :class="sel === item.id ? 'flex max-h-600px' : 'hidden max-h-0'">
                <p class="text-20px lh-normal text-primary at-tabletl:text-16px lt-tabletl:text-14px">{{ item.text }}</p>
                <NuxtLinkLocale
                  class="mt-20px w-140px h-40px flex-center text-20px border-2px border-solid border-text-blue rounded-full text-text-blue lt-tabletl:w-150px lt-tabletl:h-40px lt-tabletl:text-16px"
                  :to="item.url"
                  data-test-id="KlNw"
                >
                  {{ $t('jhoDnrJpT0EPfNkV8Iyr') }}
                </NuxtLinkLocale>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.flipy{
  transition: transform 0.3s;
  transform: scaleY(-1);
}

.htm-bar{
  --uno: 'absolute right-[-2px] w-5px bg-#266ef1 lt-tablet:w-3px';

  transition: all 0.5s ease 0s;
}

.faq-content{
  display: flex;
  align-items: center;
  width: 100%;
  -webkit-box-align: center;

  .faq-content-wrap {
    --uno: ' flex w-[100%] flex-col';

    .faq {
      --uno: 'flex w-[100%] flex-col mb-20px cursor-pointer lh-50px lt-mobile:lh-30px';
    }

    .faq-text {
      --uno: 'flex w-[100%] text-[#e2e8f2] items-start';

      .arrow-down{
        transition: all 0.5s ease-out 0s;
      }
    }

    .faq-desc {
      --uno: 'flex-col pl-40px my-16px lt-mobile:px-15px';
    }
  }
}

.collapse-wrap {
  @media (pointer: fine) {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-right: 55px;
    border-right: 2px solid #2c314b;
    scrollbar-color: rgb(0 0 0 / 50%) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: theme('colors.text.blue');
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(0 0 0 / 60%);
    }
  }

  @media bp-lt-tablet {
    padding-right: 23px;
    border-right: 1px solid #2c314b;
  }
}
</style>
