<script lang="ts">
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useAppBreakpoints } from '@peng_kai/theme/vue'

import animationData from '~/assets/imgs/json/gw2.json'
import { useAnimation } from '@/hooks/useAnimation'
</script>

<script lang="ts" setup>
import { useTgCustomer } from '~/hooks/useTgCustomer'

const tgRef = ref<Element | null>(null)

useAnimation(tgRef, animationData)

function useMotion() {
  gsap.registerPlugin(ScrollTrigger)

  onMounted(() => {
    const ctx = getCurrentInstance()
    const triggerEl = ctx?.vnode.el as HTMLElement
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
        start: '+=50% bottom',
      },
    })

    tl.fromTo(
      triggerEl.querySelector('.motion--main-title'),
      { opacity: 0, translateY: -100 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelector('.motion--main-img'),
      { opacity: 0, translateY: 100 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelectorAll('.motion--content li'),
      { opacity: 0, translateX: 100 },
      { opacity: 1, translateX: 0, stagger: 0.1, duration: 0.5 },
    )
    tl.fromTo(
      triggerEl.querySelector('.motion--main-btn'),
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 0.2 },
      '<',
    )
  })
}

const tgLink = useTgCustomer()
const { config } = useAppState()

useAppBreakpoints().laptop && useMotion()
</script>

<template>
  <section class="robot-wrapper py-[250px] bg-[#13192E] overflow-hidden at-tabletl:py-150px lt-tabletl:py-50px">
    <div class="page-content-width mx-auto main">
      <h3 class="text-[50px] text-white lh-snug font-bold mt-[50px] at-tabletl:text-[44px] at-tabletl:mt0 at-tabletl:font-bold lt-tabletl:text-center lt-tabletl:text-[30px] lt-tabletl:mt0 section-title motion--main-title">
        {{ $t('1Bj21why908kuRpRwjpRn') }}
      </h3>

      <div ref="tgRef" class="[grid-area:main-img] block w-[620px] h-auto at-tabletl:w-520px lt-tabletl:mt-[20px] lt-tabletl:w-[280px] section-main-img motion--main-img" />

      <div class="[grid-area:content] self-center motion--content mt-[-100px] lt-tabletl:mt-0">
        <ul class="[grid-area:feature-list] my-[50px] text-[#e2e8f2] text-[22px] list-none at-tabletl:text-18px lt-tabletl:text-[16px] lt-tabletl:my-[20px] feature-list">
          <li>{{ $t('zz41OJKdvT3zw3ocQgC2s') }}</li>
          <li>{{ $t('t06NhkhihYu2CPl0Lj387') }}</li>
          <li>{{ $t('BebpiEhD9lF0G5VKdL0Jd') }}</li>
          <li>{{ $t('eKx7R2GxJG-mYIvGjaY_N') }}</li>
        </ul>

        <NuxtLink class="to-page-btn motion--main-btn" target="blank" to="https://t.me/feeeIoBot"><span class="text">{{ $t('mPR27r3PKXNsf-A_YPowN') }}</span></NuxtLink>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import './toPageBtn';

.robot-wrapper > .main {
  display: grid;

  @media bp-tabletl {
    grid-template: auto 1fr / 520px auto;
    grid-template-areas: 'title main-img' 'content main-img';
    gap: 0 20px;
    place-content: center space-between;
  }

  @media bp-lt-tabletl {
    grid-template-areas: 'title' 'main-img' 'content';
    justify-items: center;
  }
}

.section-title {
  background: url('../../../assets/imgs/page-index/title-decorate.png') no-repeat left top/auto 1.5em;

  @media bp-lt-tabletl {
    background-position: center top;
  }
}

.feature-list {
  li {
    --uno: 'm-[5px_0] leading-[1.8em] marker:text-primary-100 marker:text-0.8em lt-tablet:w-auto';

    padding-left: 40px;
    background: url('@/assets/imgs/dot.svg') no-repeat 0;
  }
}
</style>
