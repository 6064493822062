<script lang="ts" setup>
import { useTgCustomer } from '~/hooks/useTgCustomer'

const tgLink = useTgCustomer()
</script>

<template>
  <section class="py-150px text-16px text-center [background:var(--bg-img)] at-tabletl:py-100px lt-tabletl:py-50px api-wrapper">
    <div class="page-content-width mx-auto main">
      <h2 class="mb-20px text-40px font-700 text-white text-center lt-tabletl:text-30px lt-tabletl:font-bold">{{ $t("yxhkRKywBbkna-w51FjwY") }}</h2>
      <div class="text-primary text-20px lh-1.4em text-center at-tabletl:text-18px lt-tabletl:text-14px text">{{ $t("VZwmrH50z2cchNJwlTFAR") }}，{{ $t("bh63isVvvIEbqMJy7S_OW") }}</div>
      <p class=" my-20px text-20px text-center at-tabletl:text-18px lt-tabletl:text-14px">
        <a class="flex-inline items-center text-white hover:text-text-blue" :href="`/console/buyer/api-key?lang=${$t('console-lang')}`" target="_blank" data-test-id="9KND">
          <i class="i-local:tg-s4 mr-0.3em" />
          {{ $t("KFJR6TWUybyp34YhUTB4a") }}
        </a>
      </p>
      <p class="text-primary at-tabletl:text-18px lt-tabletl:text-14px info">
        {{ $t("atev9Gp13LMBqZ8mDP-GQ") }}
        <a :href="tgLink" target="_blank" style="color: var(--highlight)" data-test-id="wHyM">{{ $t("UmimAwmgG6sCuG8QrHAgY") }}</a>
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.api-wrapper {
  --bg-img: url("@/assets/imgs/page-index/api-bg.svg") repeat;
}
</style>
