<script setup lang="ts">
import View from '~/pages-components/index/index.vue'

const i18nHead = useLocaleHead({ addSeoAttributes: true })
useHead({
  title: () => $t('ZtzqRdD1HHSKd7-9mLEPS'),
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [
    ...(i18nHead.value.link || []),
  ],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
  ],
})
</script>

<template>
  <View v-cloak />
</template>
