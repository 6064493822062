<script lang="ts">
import { useAppBreakpoints } from '@peng_kai/theme/vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import animationData from '~/assets/imgs/json/gw1b.json'
import { useAnimation } from '@/hooks/useAnimation'

function useMotion() {
  gsap.registerPlugin(ScrollTrigger)

  onMounted(() => {
    const ctx = getCurrentInstance()
    const triggerEl = ctx?.vnode.el as HTMLElement
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
        start: '+=30% bottom',
      },
    })

    tl.fromTo(
      triggerEl.querySelector('.motion--main-title'),
      { opacity: 0, translateY: -100 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelector('.motion--main-img'),
      { opacity: 0, translateY: 100 },
      { opacity: 1, translateY: 0, duration: 0.5 },
      0,
    )
    tl.fromTo(
      triggerEl.querySelectorAll('.motion--content li'),
      { opacity: 0, translateX: 100 },
      { opacity: 1, translateX: 0, stagger: 0.1, duration: 0.5 },
    )
    tl.fromTo(
      triggerEl.querySelector('.motion--main-btn'),
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 0.2 },
      '<',
    )
  })
}
</script>

<script lang="ts" setup>
const { config } = useAppState()
const sun = toRef(() => config.value?.price_c2c.energy.sun ?? '-')
const tradeRef = ref<Element | null>(null)

useAnimation(tradeRef, animationData)
useAppBreakpoints().laptop && useMotion()
</script>

<template>
  <section class="exchange-wrapper py-250px overflow-hidden bg-#161D36 at-tabletl:py-150px lt-tabletl:py-50px">
    <div class="page-content-width mx-auto main">
      <h3 class="text-50px text-white lh-snug font-700 mt-50px at-tabletl:text-44px at-tabletl:mt-0 at-tabletl:font-bold lt-tabletl:text-center lt-tabletl:text-30px lt-tabletl:mt-0 section-title motion--main-title">
        {{ $t('yiSrOfcmDFQ4S_64vwKmE') }}
      </h3>

      <div ref="tradeRef" class="[grid-area:main-img] block w-620px h-auto at-tabletl:w-520px lt-tabletl:mt-20px lt-tabletl:w-280px section-main-img motion--main-img" />
      <div class="[grid-area:content] self-center motion--content mt-[-50px] lt-tabletl:mt-0">
        <ul class="[grid-area:feature-list] my-50px text-#e2e8f2 text-22px list-none at-tabletl:text-18px lt-tabletl:text-16px lt-tabletl:my-20px feature-list">
          <li>{{ $t('VQ2u-_O2KB9TvYBYJ2Vz4') }}</li>
          <li>{{ $t('k83KJ9fav-akzUlFuKKIz') }} {{ sun }} {{ $t('JhE-yNAHL6Vn7vKIW1kjf') }}</li>
          <li>{{ $t('M4qmYyQa4lrIUiRXbvicI') }}</li>
          <li>{{ $t('j5p1KAoH_GVbkaospvey9') }}</li>
          <li>{{ $t('OrkE0s5e1PpALACYweSdy') }}</li>
        </ul>

        <NuxtLink class="to-page-btn motion--main-btn" :to="localePath('/c2c')"><span class="text">{{ $t('32glPwjz3uSyHb3gv19Nk') }}</span></NuxtLink>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import './toPageBtn';

.exchange-wrapper > .main {
  display: grid;

  @media bp-tabletl {
    gap: 0 20px;
    grid-template: auto 1fr / auto 520px;
    grid-template-areas: 'main-img title' 'main-img content';
    place-content: start space-between;
  }

  @media bp-lt-tabletl {
    grid-template-areas: 'title' 'main-img' 'content';
    justify-items: center;
  }
}

.section-title {
  background: url('@/assets/imgs/page-index/title-decorate.png') no-repeat left top/auto 1.5em;

  @media bp-lt-tabletl {
    background-position: center top;
  }
}

.feature-list {
  li {
    --uno: 'm-[5px_0] leading-[1.8em] marker:text-primary-100 marker:text-0.8em lt-tablet:w-auto';

    padding-left: 40px;
    background: url('@/assets/imgs/dot.svg') no-repeat 0;
  }
}
</style>
