<script lang="ts">
import * as d3Banner from './D3Banner.mjs'
import { useAnimation } from '@/hooks/useAnimation'
import animationData from '~/assets/imgs/json/sj.json'

function loadScript(url: string) {
  return new Promise((reslove) => {
    import.meta.env.SSR && reslove(undefined)
    document.querySelectorAll(`script[src="${url}"]`)?.forEach(script => script.remove())

    const headTag = document.querySelector('head')
    const scriptTag = document.createElement('script')
    scriptTag.src = url
    scriptTag.onload = reslove
    headTag?.appendChild(scriptTag)
  })
}
</script>

<script lang="ts" setup>
const d3Ref = ref<HTMLElement>()
const loadedThreejs = ref(false)
const bannerRef = ref<Element | null>(null)

useAnimation(bannerRef, animationData)

watch([d3Ref, loadedThreejs], ([d3Ref, loadedThreejs]) => {
  if (import.meta.env.SSR)
    return

  if (d3Ref && loadedThreejs) {
    d3Banner.init(d3Ref)
    d3Banner.animate()
  }
  else {
    d3Banner.stopAnimate()
  }
}, { immediate: true })

onUnmounted(() => {
  d3Banner.stopAnimate()
})

loadScript('/banner3d/three.min.js').then(() => {
  loadedThreejs.value = true
})
</script>

<template>
  <div class="banner-wrapper">
    <!-- 大屏 -->
    <div class="hidden tabletl:block mx-auto page-max-width h-650px overflow-hidden lg-screen">
      <div class="relative h-[calc(100%_+_var(--bottom-margin))]">
        <!-- 背景文字 -->
        <div
          class="w-100% h-100% text-size-200px font-extrabold text-transparent abs-center flex-center bg-clip-text [background-image:var(--bg-color)] after:abs-center after:content-[''] after:w-100% after:h-100% after:[background-image:var(--bg-diffusion-color)]"
        >
          Fee&nbsp;&nbsp;e.io
        </div>
        <!-- Canvas 挂载点 -->
        <ClientOnly>
          <div v-if="$bp.tabletl" ref="d3Ref" class="absolute abs-center w-1200px h-100% scale-120" />
        </ClientOnly>
        <h1 class="pointer-events-none w-800px text-center font-bold text-size-50px text-white abs-center name lh-1.5em"><span />TRON{{ $t('CKos3kYJr13GVD9WqZmrO') }}</h1>
      </div>
    </div>

    <!-- 小屏 -->
    <div class="hidden lt-tabletl:block pt-30px sm-screen">
      <div class="text-center pb-10px w-fit mx-auto gradient-text">
        <h1 class="text-110px font-black">TRON</h1>
        <p class="text-26px font-extrabold lh-snug">{{ $t('CKos3kYJr13GVD9WqZmrO') }}</p>
      </div>
      <div ref="bannerRef" class="w-full max-w-600px h-auto mx-auto block" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* 大屏 */
.lg-screen {
  --bottom-margin: 0px;
  --bg-color: radial-gradient(circle, rgb(0 25 107 / 100%) 6%, rgb(255 255 255 / 100%) 74%);
  --bg-diffusion-color: radial-gradient(ellipse closest-side at center, #00196b, rgb(255 255 255 / 0%));
}

.gradient-text {
  background-clip: text;
  background-image: linear-gradient(270deg, #00c1a5 , #2680eb);
  -webkit-text-fill-color: transparent;
}
</style>
