let container
let camera
let scene
let renderer
let controls
let obj
let manager
let onProgress
let onError
const mouseX = 0
const mouseY = 0
let winWidth = 0
let winHeight = 0
let windowHalfX = 0
let windowHalfY = 0
let animationFrame = 0

export function init(ctn) {
  container = ctn
  winWidth = container.clientWidth
  winHeight = container.clientHeight
  windowHalfX = winWidth / 2
  windowHalfY = winHeight / 2

  camera = new THREE.PerspectiveCamera(31, winWidth / winHeight, 1, 4000)
  camera.position.z = 250

  // 摄影机环绕
  controls = new THREE.OrbitControls(camera)
  controls.autoRotate = true
  controls.enabled = false // 是否启用鼠标拖拽
  controls.autoRotateSpeed = -1
  controls.update()

  // scene
  scene = new THREE.Scene()

  const ambientLight = new THREE.AmbientLight(0xFFFFFF, 0.5)
  scene.add(ambientLight)

  const pointLight = new THREE.DirectionalLight(0x121E42, 4)
  pointLight.position.set(250, 250, 220) // 平行光源位置
  camera.add(pointLight)
  scene.add(camera)

  manager = new THREE.LoadingManager()

  // 添加对象
  addTronNew()
  addTronLizi('/banner3d/TRON_lizi1.obj')
  addTronLizi('/banner3d/TRON_lizi2.obj')
  addTronLizi('/banner3d/TRON_lizi3.obj')
  addTronLizi('/banner3d/TRON_lizi4.obj')

  try {
    renderer = new THREE.WebGLRenderer({ alpha: true })
    renderer.domElement.id = 'banner-canvas'
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(winWidth, winHeight)
  }
  catch (err) {
    console.log('🤡 / init / err:', err)
  }

  container.appendChild(renderer.domElement)
  container.addEventListener('mousemove', onDocumentMouseMove, false)
}

function onDocumentMouseMove(event) {
  controls.autoRotateSpeed = (event.clientX - windowHalfX) / 100
}

export function animate() {
  animationFrame = requestAnimationFrame(animate)
  render()
}

export function stopAnimate() {
  cancelAnimationFrame(animationFrame)
}

function render() {
  controls.update()
  controls.autoRotateSpeed = -1

  camera.lookAt(scene.position)

  renderer.render(scene, camera)
}

function addTronNew() {
  const loader = new THREE.OBJLoader(manager)

  const cubetLoader = new THREE.CubeTextureLoader()
  let CubeTexture
  CubeTexture = cubetLoader.load([
    '/banner3d/h.jpeg',
    '/banner3d/q.jpeg',
    '/banner3d/s.jpeg',
    '/banner3d/x.jpeg',
    '/banner3d/y.jpeg',
    '/banner3d/z.jpeg',
  ])
  loader.load(
    '/banner3d/TRON_new.obj',
    (object) => {
      const sphereMaterial = new THREE.MeshPhongMaterial({
        color: 0x2052FF,
        specular: 0xFFFFFF,
        shininess: 4,
        envMap: CubeTexture, // 设置环境贴图
        reflectivity: 0.5,
      }) // 材质对象

      object.traverse((child) => {
        if (child instanceof THREE.Mesh)
          child.material = sphereMaterial
      })

      // 旋转方向
      object.rotateY(Math.PI / 2)
      object.rotateX(Math.PI / 0.292)
      object.scale.set(0.7, 0.7, 0.7)
      object.position.y = 10
      obj = object
      scene.add(object)
    },
    onProgress,
    onError,
  )
}

function addTronLizi(obj) {
  const loader = new THREE.OBJLoader(manager)
  loader.load(
    obj,
    (object) => {
      const sphereMaterial = new THREE.MeshPhongMaterial({
        color: 0x2A47AB,
        specular: 0xFFFFFF,
        shininess: 0,
      }) // 材质对象

      object.traverse((child) => {
        if (child instanceof THREE.Mesh)
          child.material = sphereMaterial
      })

      for (let i = 0; i <= 50; i++) {
        const newObj = object.clone() // 复制对象
        const sr = 1 / getRndInteger(3, 8)
        newObj.scale.set(sr, sr, sr)
        newObj.rotateY(Math.PI / getRndInteger(1, 10))
        newObj.rotateX(Math.PI / getRndInteger(1, 10))
        newObj.position.x = getRndInteger(-100, 100)
        newObj.position.y = getRndInteger(-10, 10)
        newObj.position.z = getRndInteger(-100, 100)
        scene.add(newObj)
      }
    },
    onProgress,
    onError,
  )
}

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}
